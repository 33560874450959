import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Odin } from '../src/components/Odin/Odin';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Loki from '../src/components/Loki/lokipane';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import SEO from '../src/helpers/seo';
import Hod from '../src/components/Hod/Hod';
import { ImageText } from '../src/components/ImageText/ImageText';

import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';
import { Freya } from '../src/components/Freya/Freya';

import './fundacion.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query fundacion($locale: String!) {
    allFundacionSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allFundacionTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allFundacionTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          image
          image_alt
        }
      }
    }
    allFundacionHeaderImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFundacionMobileHeaderImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFundacionBreadcrumb(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allFundacionContacto(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body_left
          body_right
          cta_odin
          ctaText_odin
        }
      }
    }
    allFundacionLokiBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allFundacionFreyaBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          title
          description
          cta
          ctaText
          popup_copy
          title_popup
          content_popup
        }
      }
    }
    allFundacionTopPromoted(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          buttons {
            cta
            ctaText
            size
            color
          }
        }
      }
    }
    allFundacionRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
    allFundacionImageTextBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          cta
          cta_text
          cta_size
          cta_colour
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

class Fundacion extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allFundacionTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allFundacionHeaderImage.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allFundacionHeaderImage.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allFundacionMobileHeaderImage.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allFundacionMobileHeaderImage.edges[0].node.alt,
      },
    };
    const odinData = {
      image: this.props.data.allFundacionTitle.edges[0].node.image,
      alt: this.props.data.allFundacionTitle.edges[0].node.image_alt,
      description: this.props.data.allFundacionTitle.edges[0].node.description,
      buttons: {
        cta1: this.props.data.allFundacionContacto.edges[0].node.cta_odin,
        ctaText1: this.props.data.allFundacionContacto.edges[0].node.ctaText_odin,
        size: 'alone',
        color1: 'white',
      },
    };

    const contentData = {
      title: this.props.data.allFundacionTitleBlock.edges[0].node.description,
      body_left: this.props.data.allFundacionContacto.edges[0].node.body_left,
      body_right: this.props.data.allFundacionContacto.edges[0].node.body_right,
    };

    const titleData = {
      title: tt('CONTACTO', this.props.pageContext.locale),
    };

    const lokiData = {
      title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
      arrayImg: this.props.data.allFundacionLokiBlock.edges
        .map((block) => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.image_alt,
        }))
        .filter((item) => item.img !== null),

      bigArrayImg: this.props.data.allFundacionLokiBlock.edges
        .map((block) => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.image_alt,
        }))
        .filter((item) => item.img !== null),
    };

    const freyaData = {
      title: tt('PROYECTOS', this.props.pageContext.locale),
      isSlider: true,
      cards: this.props.data.allFundacionFreyaBlock.edges.map((project) => {
        if (project.node.title_popup) {
          return {
            image: project.node.localImage.childImageSharp.fluid,
            altImage: project.node.image_alt,
            imgsize: 'promoted',
            title: project.node.title,
            description: project.node.description,
            buttons: {
              size: 'alone',
              color: 'white',
              ctaText: project.node.popup_copy,
              popup: {
                enable: true,
                textpop: project.node.content_popup,
              },
            },
          };
        }
        return {
          image: project.node.localImage.childImageSharp.fluid,
          title: project.node.title,
          description: project.node.description,
          imgsize: 'promoted',
          buttons: {
            cta: project.node.cta,
            size: 'alone',
            color: 'white',
            ctaText: project.node.ctaText,
          },
        };
      }),
    };

    const ImageTitleData = {
      cards: this.props.data.allFundacionImageTextBlock.edges.map((card) => {
        return {
          image: card.node.localImage.childImageSharp.fluid,
          alt: card.node.alt,
          title: card.node.title,
          description: card.node.description,
          buttons: {
            cta: card.node.cta,
            size: card.node.cta_size,
            color: card.node.cta_colour,
            ctaText: card.node.cta_text,
          },
        };
      }),
    };

    const freyaData2 = {
      title: tt('RESPONSABILIDAD CORPORATIVA', this.props.pageContext.locale),
      cards: this.props.data.allFundacionTopPromoted.edges.map((promotion) => ({
        image: promotion.node.localImage.childImageSharp.fluid,
        altImage: promotion.node.image_alt,
        title: promotion.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: promotion.node.buttons.cta,
          size: 'alone',
          color: 'white',
          ctaText: promotion.node.buttons.ctaText,
        },
      })),
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <SEO
            title={this.props.data.allFundacionSeoBlock.edges[0].node._0.title}
            description={this.props.data.allFundacionSeoBlock.edges[0].node._1.description}
            pathname={this.props.pageContext.url}
            lang={this.props.pageContext.locale}
          />
          <Hod
            data={this.props.data.allFundacionRichData.edges[0].node.rich_markup}
            url={this.props.pageContext.url}
            img={this.props.data.allFundacionHeaderImage.edges[0].node.image}
          />
          <div className="general-index">
            <div className="fundacion-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allFundacionBreadcrumb.edges[0].node.name}
              />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
              <div className="main-container">
                <div className="left-container">
                  <H2 data={contentData} />
                  <div
                    className="text-dots"
                    dangerouslySetInnerHTML={{ __html: contentData.body_left }}
                  />
                </div>
                <div className="right-container">
                  <div className="standard-box">
                    <H3 data={titleData} />
                    <div
                      className="text-box"
                      dangerouslySetInnerHTML={{ __html: contentData.body_right }}
                    />
                  </div>
                </div>
              </div>
              <ImageText data={ImageTitleData} />
              <H4 title={lokiData.title} />
              <Loki data={lokiData} />
              <div className="promotions-content">
                <H3 data={freyaData} />
                <Freya data={freyaData} />
              </div>
              <div className="responsabilidad-content">
                <H3 data={freyaData2} />
                <Freya data={freyaData2} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Fundacion;
