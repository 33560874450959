import PropTypes from 'prop-types';
import React from 'react';
import './ImageText.scss';
import Img from 'gatsby-image/withIEPolyfill';
import Buttons from '../Buttons/Buttons';

export const ImageText = props => {
  const {
    data: { cards },
  } = props;
  const mapCards = array =>
    array.map((e, index) => {
      return (
        <div className="ImageText-card-container" key={index}>
          <Img className="img-ImageText" fluid={e.image} alt={e.alt} />
          <div className="ImageText-card-text-content">
            <span className="ImageText-card-description">{e.description}</span>
            <a
              href={e.buttons.cta}
              className={`ImageText-button ${e.buttons.size} ${e.buttons.color}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {e.buttons.ctaText}
            </a>
          </div>
        </div>
      );
    });
  return <div className="ImageText-container">{mapCards(cards)}</div>;
};

ImageText.propTypes = {
  data: PropTypes.object.isRequired,
};
